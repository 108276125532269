
import React from 'react'
import {url} from '../helpers/helpers'
import {userContext} from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'

export default function TransporterList() {

    const [transporterList,setTransporterList] = React.useState([])
    const [loading,setLoading] = React.useState(false)

    React.useEffect(()=>{
        setLoading(true)
        async function fetchData(){
            const response = await fetch(url + 'fetchData/')

            if (response.ok == true){
                const data = await response.json()
                setTransporterList(data.transporter)
                setLoading(false)
            }
        }

        fetchData()
    },[])

    const {user} = React.useContext(userContext)
    if (!user){
        return window.location = window.location.origin + '/signInPage'
    }

    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{display:'flex',flexDirection:'row'}}>
                <PanelSidebar />
                <div style={{width:'100%',margin:'0 0.5%'}}>
                {transporterList.length > 0 ? <table>
                <thead style={{background:'ghostwhite'}}>
                    <th>Sr. No.</th>
                    <th>Transporter Name</th>
                    <th>Gst</th>
                    <th>Mobile</th>
                    <th></th>
                    <th></th>
                </thead>

                {transporterList.map((item,index)=>{
                   return <tr>
                       <td>{index + 1}</td>
                       <td>{item.name}</td>
                       <td>{item.city}</td>
                       <td>{item.mobile}</td>
                       <td><a target='_blank' href={`${url}admin/erpApp/transporter/${item.id}/change/`}>Edit</a></td>
                       <td><a target='_blank' href={`${url}admin/erpApp/transporter/${item.id}/delete/`}>Delete</a></td>
                   </tr> 
                })}

            </table> : <h1>No Data Found Please Wait</h1>}
                </div>
            </div>
            <Footer />
        </section>
    )
}