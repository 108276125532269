import React from 'react'
import { Link } from 'react-router-dom'
import { url,formatDate } from '../helpers/helpers'
import { userContext } from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'

export default function UnsavedBillList() {
    const [allReports, setAllReports] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        // setLoading(true)
        async function fetchReports() {
            const response = await fetch(url + 'unsavedBill/')
            if (response.ok === true) {
                const data = await response.json()
                console.log(data.final_data)
                setAllReports(data.final_data)
                // setLoading(false)
                return
            }
            // setLoading(true)
        }
        fetchReports()
    }, [])

    const { user } = React.useContext(userContext)
    if (!user) {
        return window.location = window.location.origin + '/signInPage'
    }

    return (
        <>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{width:'100%',margin:'0 0.5%'}}>
                    {allReports.length > 0 ? <section className="table-report">
                        <table>
                            <thead style={{ background: 'lightgray' }}>
                                <th>Sr. No</th>
                                <th>Party Name</th>
                                <th>Invoice Number</th>
                                <th>Invoice Date</th>
                                <th></th>
                            </thead>
                            {allReports.map((item, index) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.client}</td>
                                    <td>{item.invoiceNo}</td>
                                    <td>{formatDate(item.date)}</td>
                                    <td><Link to={`/unsavedBill/${item.header_id}`}>Edit</Link></td>
                                </tr>
                            })}
                        </table>

                    </section> : <h1 className="mt-5 ml-5">No Data Found Please Wait</h1>}
                </div>
            </div>
            <Footer />
        </>
    )
}

