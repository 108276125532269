import React from 'react'

export default function Footer() {
    var d = new Date()
    return (
        <footer style={{width:'100%',textAlign:'center',color:'white'}}>
            <p style={{marginBottom:'0px !important',letterSpacing:1}}>&copy; Copyright {d.getFullYear()} <a href='http://ramlalsohanlal.com/'>Ram Lal Sohanlal</a> Software developed by <a target='_blank' href="mailto:alihussainkabri52@gmail.com"> <strong>Alihussain Kabri</strong>  <i style={{fontSize:20}} class="fa fa-envelope-o" aria-hidden="true"></i></a> || Powered by <a target='_blank' href="https://themad.in/"><strong>THE MAD - The Marketers And Developer</strong></a> </p>
        </footer>
    )
}
