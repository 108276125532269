import React from 'react'
import Logo from '../4.jpeg'
import {userContext} from '../context/user'
import {Link} from 'react-router-dom';
import {url} from '../helpers/helpers'

export default function PanelNav({ sidebarOpen, openSidebar }) {
    const {user,setUser} = React.useContext(userContext)

    const logout = () => {
        async function logoutUser(){
          const response = await fetch(url + 'signOut/')
    
          if (response.ok == true){
            setUser('')
          }
        }
        logoutUser()
      }
    return (
        <nav className='navbar'>
            <div className='nav_icon' onClick={() => openSidebar()}>
                <i className='fa fa-bars'></i>
            </div>
            <img className='navbar__img__logo' src={Logo} />
            <div className='navbar__right'>

            {user ? <li className='nav-item' style={{color:'gray',marginTop:-5,listStyle:'none',cursor:'pointer'}}>
                <span className='nav-link'  onClick={()=>logout()}>Sign Out</span>
              </li> : <li className='nav-item' style={{marginTop:-5,listStyle:'none'}}>
                <Link className='nav-link' style={{color:'gray'}}  to='/signInPage/'>Sign In</Link>
              </li>}
                
                {/* <a href="#" style={{color:'grey'}} >
                    Logout
                </a> */}
            </div>
        </nav>
    )
}
