import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate, url, media_url } from '../helpers/helpers'
import { userContext } from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'

export default function SalesReport() {
    const [allReports, setAllReports] = React.useState([])
    const [fromDate, setFromDate] = React.useState('')
    const [toDate, setToDate] = React.useState('')
    const [loading, setLoading] = React.useState(false);
    const [partyNames, setPartyNames] = React.useState([]);
    const [currentParty, setCurrentParty] = React.useState('');
    const [allReportsStore, setAllReportsStore] = React.useState([]);
    const [file, setFile] = useState('')
    const [biltyID, setBiltyID] = useState('')

    React.useEffect(() => {
        setLoading(true)
        async function fetchReports() {
            const response = await fetch(url + 'fetchReports/')
            if (response.ok === true) {
                const data = await response.json()
                setAllReports(data.final_data);
                setAllReportsStore(data.final_data);
                setPartyNames(data.party_data);
                setLoading(false);
            }
        }
        fetchReports()
    }, [])

    const deleteBill = (id) => {
        let answer = window.confirm('Are You Sure You Want To Delete This Entry?');
        if (answer == true) {
            setLoading(true)
            async function deleteItem() {
                const response = await fetch(url + 'deleteBill/' + id + '/')

                if (response.ok === true) {
                    const data = await response.json()
                    setAllReports(data.final_data)
                    setLoading(false)
                }
            }
            deleteItem()
        } else {
            return;
        }
    }

    const handleFilter = () => {
        if (fromDate && toDate) {
            setLoading(true)
            const formData = new FormData()
            formData.append('from_date', fromDate)
            formData.append('to_date', toDate)
            async function filterData() {
                const response = await fetch(url + 'filterReports/', {
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': csrftoken,
                    },
                    body: formData
                })

                if (response.ok === true) {
                    const data = await response.json()
                    setAllReports(data.final_data)
                    setLoading(false)
                }
            }
            filterData()
        } else {
            alert('please fill the data')
        }
    }

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');

    const { user } = React.useContext(userContext)
    if (!user) {
        return window.location = window.location.origin + '/signInPage'
    }

    const handleChangeParty = (value) => {
        setCurrentParty(value);
        if (value) {
            let value_arr = value.split('-');

            if (value_arr[1]) {
                let matched_results = [];
                allReportsStore.map(item => {
                    if (item.client.toLowerCase().search(value_arr[1].toLowerCase()) > -1) {
                        matched_results.push(item);
                    }
                })

                setAllReports(matched_results);
            }
        } else {
            setAllReports(allReportsStore);
        }
    }

    async function sendWP(id, main_url) {
        setLoading(true)
        const formData = new FormData()
        formData.append('header_id', id)
        const response = await fetch(url + main_url, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
            },
            body: formData
        })

        if (response.ok == true) {
            setLoading(false)
            alert('message sent')
        } else {
            setLoading(false)
            alert('something went wrong!')
        }
    }

    async function uploadBilty() {
        if (biltyID && file) {
            setLoading(true)
            const formData = new FormData()
            formData.append('header_id', biltyID)
            formData.append('file', file, file.name)

            const response = await fetch(url + 'uploadBuiltin/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
                body: formData
            })

            if (response.ok == true) {
                setLoading(false)
                alert('bilty update')
                window.location.reload()
            } else {
                setLoading(false)
                alert('something went wrong')
            }
        }

    }

    async function sendBilty(id) {

        setLoading(true)
        const formData = new FormData()
        formData.append('header_id', id)

        const response = await fetch(url + 'sendBiltyMessage/', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
            },
            body: formData
        })

        if (response.ok == true) {
            setLoading(false)
            alert('bilty sent')
        } else {
            setLoading(false)
            alert('something went wrong')
        }


    }

    return (
        <>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload Bilty</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className='form-group'>
                                <label>Upload Image</label>
                                <input type='file' onChange={e => setFile(e.target.files[0])} />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button onClick={uploadBilty} type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                {loading && <div id="loader"></div>}
                <PanelNav />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <PanelSidebar />
                    <div className='w-100'>
                        <div className="display-div border">
                            <div className="form-group">
                                <label htmlFor="">Party</label>
                                <input list='party-list' autoComplete="off" className='form-control' value={currentParty} onChange={e => handleChangeParty(e.target.value)} />
                            </div>

                            <datalist id='party-list'>
                                <option value="">Select Party</option>
                                {partyNames.length > 0 && partyNames.map(item => (
                                    <option>
                                        {`${item.id}-${item.name}`}
                                    </option>
                                ))}
                            </datalist>

                            <div className="form-group">
                                <label htmlFor="">From Date</label>
                                <input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} className='form-control' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">To Date</label>
                                <input type="date" value={toDate} onChange={e => setToDate(e.target.value)} className='form-control' />
                            </div>
                            <div className='btn-div'>
                                <button onClick={() => handleFilter()} className='btn btn-primary'>Submit</button>
                            </div>
                        </div>
                        {allReports.length > 0 ? <section className="table-report">
                            <table style={{ margin: '0px 0.6%' }}>
                                <thead style={{ background: 'lightgray' }}>
                                    <th>Sr. No</th>
                                    <th>Action</th>
                                    <th>Party Name</th>
                                    <th>Invoice Number</th>
                                    <th>Invoice Date</th>
                                    <th>Final Amount</th>
                                    <th>GR No.</th>
                                    <th>Category</th>


                                </thead>
                                {allReports.map((item, index) => {
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    More
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <Link class="dropdown-item" to={`/editBill/${item.header_id}`}>Edit</Link>
                                                    <a class="dropdown-item" href="javascript:void(0);" onClick={() => deleteBill(item.header_id)}>Delete</a>
                                                    <a href={`${url}printChallan/${item.uuid}/`} target='_blank' class="dropdown-item">Print Challan</a>
                                                    <a href={`${url}printBill/${item.uuid}/`} target='_blank' class="dropdown-item">Print Bill</a>
                                                    <a class="dropdown-item" href="javascript:void(0);" onClick={() => sendWP(item.header_id, 'send-wp-message/')}>Send WP</a>
                                                    <a class="dropdown-item" href="javascript:void(0);" onClick={() => sendWP(item.header_id, 'sendGRNMessage/')}>Send GRN</a>
                                                    <a class="dropdown-item" href="javascript:void(0);" onClick={() => {
                                                        setBiltyID(item?.header_id)
                                                        setTimeout(() => {
                                                            document.getElementById('launch-modal-bilty').click()
                                                        }, 500);
                                                    }}>Upload Bilty</a>
                                                    {item?.bilty?.length > 0 && <a target='_blank' class="dropdown-item" href={`${media_url}${item?.bilty}`}>
                                                        Show Bilty
                                                    </a>}
                                                    {item?.bilty?.length > 0 && <a class="dropdown-item" href='javascript:void(0);' onClick={() => sendBilty(item?.header_id)}>
                                                        Send Bilty
                                                    </a>}
                                                    <button type="button" class="d-none btn btn-primary" id='launch-modal-bilty' data-toggle="modal" data-target="#exampleModal">
                                                        Launch demo modal
                                                    </button>
                                                </div>
                                            </div>


                                        </td>
                                        <td>{item.client}</td>
                                        <td>{item.invoice}</td>
                                        <td>{formatDate(item.date)}</td>
                                        <td>{parseFloat(item.finalAmount).toFixed(2)}</td>
                                        <td>{item.remarks}</td>
                                        <td style={{ textTransform: 'capitalize' }}>{item.category}</td>
                                    </tr>
                                })}
                            </table>

                        </section> : <div className="w-100">
                            <h1 className="mt-5 ml-5">No Data Found Please Wait</h1>
                        </div>}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
