import React from 'react'
import { Link } from 'react-router-dom'
import { url } from '../helpers/helpers'

export default function PanelSidebar({ sidebarOpen, closeSidebar }) {
    let currentPath = window.location.pathname;
    return (
        <div className={sidebarOpen ? 'sidebar-responsive' : ''} id='sidebar' >
            <div className='sidebar__title'>
                {/* <div className='sidebar__image'>
                    <h1>Logo</h1>
                </div> */}
                <i className='fa fa-times' id='sidebarIcon' onClick={() => closeSidebar()}></i>
            </div>
            <div className='sidebar__menu'>
                <div className={currentPath == '/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                    <i className='fa fa-home'></i>
                    <a href='/'>Dashboard</a>
                </div>

                <div id="accordion">

                    <div id="headingOne">
                        <h5 class="mb-0">
                            <a class="text-decoration-none accordion_manual_heading_sidebar" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Entry
                            </a>
                        </h5>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className={currentPath == '/billEntry/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-user-secret'></i>
                            <Link to="/billEntry/">Bill Entry</Link>
                        </div>
                        <div className={currentPath == '/createitem/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-user-secret'></i>
                            <Link to="/createitem/">Create Item</Link>
                        </div>
                        <div className={currentPath == '/addWorkToEmployee/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-user-secret'></i>
                            <Link to="/addWorkToEmployee/">Add Work To Employee</Link>
                        </div>
                        <div className={currentPath == '/unsavedBillList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-user-secret'></i>
                            <Link to="/unsavedBillList/">Unsaved Bill</Link>
                        </div>
                    </div>


                    <div id="headingTwo">
                        <h5 class="mb-0">
                            <a class="text-decoration-none accordion_manual_heading_sidebar collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Entry List
                            </a>
                        </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className={currentPath == '/rawMaterialsList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/rawMaterialsList/">Raw Material List</Link>
                        </div>
                        <div className={currentPath == '/packingMaterialsList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/packingMaterialsList/">Packaging Material List</Link>
                        </div>
                        <div className={currentPath == '/extraExpensesList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/extraExpensesList/">Extra Expense List</Link>
                        </div>
                        <div className={currentPath == '/CostingList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/CostingList/">Costing List</Link>
                        </div>
                        <div className={currentPath == '/employeeList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/employeeList/">Employee List</Link>
                        </div>
                        <div className={currentPath == '/productsList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/productsList/">Product List</Link>
                        </div>
                        <div className={currentPath == '/clientList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-user-secret'></i>
                            <Link to="/clientList/">Party List</Link>
                        </div>
                        <div className={currentPath == '/transporterList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-building-o'></i>
                            <Link to="/transporterList/">Transporter List</Link>
                        </div>
                        <div className={currentPath == '/loaderList/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/loaderList/">Loader List</Link>
                        </div>
                    </div>

                    <div id="headingThree">
                        <h5 class="mb-0">
                            <a class="text-decoration-none accordion_manual_heading_sidebar collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Masters
                            </a>
                        </h5>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/rawmaterial/add/`}>Create Raw Materials</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/packingmaterial/add/`}>Create Packing Materials</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/extraexpense/add/`}>Create Extra Expense</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/employee/add/`}>Create Employee</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-building-o'></i>
                            <a href={`${url}admin/erpApp/product/add/`}>Create Product</a>
                        </div>
                        <div className='sidebar__link'>
                            <i className='fa fa-user-secret'></i>
                            <a href={`${url}admin/erpApp/productcategory/add/`}>Create Category</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-handshake-o'></i>
                            <a href={`${url}admin/erpApp/inchunit/`}>Inch Unit</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-wrench'></i>
                            <a href={`${url}admin/erpApp/gramunit/`}>Gram Unit</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/party/add/`}>Create Party</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/transporter/add/`}>Create Transporter</a>
                        </div>
                        <div className="sidebar__link">
                            <i className='fa fa-archive'></i>
                            <a href={`${url}admin/erpApp/loader/add/`}>Create Loader</a>
                        </div>

                    </div>

                    <div id="headingFour">
                        <h5 class="mb-0">
                            <a class="text-decoration-none accordion_manual_heading_sidebar collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                Reports
                            </a>
                        </h5>
                    </div>

                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className={currentPath == '/salesReport/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-user-secret'></i>
                            <Link to="/salesReport/">Reports</Link>
                        </div>
                        <div className={currentPath == '/dayWiseTotalSales/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-building-o'></i>
                            <Link to="/dayWiseTotalSales/">Day Wise</Link>
                        </div>
                        <div className={currentPath == '/productWiseTotalSales/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/productWiseTotalSales/">Product Wise</Link>
                        </div>
                        <div className={currentPath == '/productWiseDetailSales/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/productWiseDetailSales/">Product Wise Detail</Link>
                        </div>
                        <div className={currentPath == '/partyWiseDetailSales/' ? 'sidebar__link active_menu_link' : 'sidebar__link'}>
                            <i className='fa fa-wrench'></i>
                            <Link to="/partyWiseDetailSales/">Party Wise Detail</Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
