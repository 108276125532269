import React from "react";
import { Link } from "react-router-dom";
import { url } from "../helpers/helpers";
import { userContext } from "../context/user";
import Footer from "../components/Footer";
import PanelNav from "../components/PanelNav";
import PanelSidebar from "../components/PanelSidebar";

export default function Home() {
    const [allCounts, setAllCounts] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const [time, setTime] = React.useState("");

    React.useEffect(() => {
        async function fetchCounts() {
            const response = await fetch(url + "fetchCounts/");

            if (response.ok === true) {
                const data = await response.json();
                setAllCounts(data.data);
            }
        }
        fetchCounts();
    }, []);

    const takeBackup = () => {
        setLoading(true);
        async function take() {
            const response = await fetch(url + "takeBackup/");
            if (response.ok === true) {
                const data = await response.json();
                setStatus(data.date);
                setTime(data.time);
                setLoading(false);
                alert("Backup has been taken successfully");
            } else {
                setLoading(false);
                alert("unable to take backup");
            }
        }
        take();
    };

    const { user } = React.useContext(userContext);
    if (!user) {
        return (window.location = window.location.origin + "/signInPage");
    }

    return (
        <>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: "flex", flexDirection: "row" }}>
            <PanelSidebar />
                <div className="main__container">
                    <div className="main__cards">
                        <div className="card">
                            <i className='fa fa-user-o fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom:'0 !important'}}>No. Of Clients</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.client}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-truck fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom:'0 !important'}}>No. Of Transporter</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.transporter}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-shopping-cart fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom : '0 !important'}}>No. Of Products</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.product}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-inr fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom : '0 !important'}}>No. Of GST Unit</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.gst}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-slack fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom : '0 !important'}}>No. Of INCH Unit</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.inch}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-usd fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom : '0 !important'}}>Today Sales</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.amount}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-briefcase fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom : '0 !important'}}>Total Boxes</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.box}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className='fa fa-balance-scale fa-2x text-lightblue'></i>
                            <div className="card_inner">
                                <p className='text-primary-p' style={{marginBottom : '0 !important'}}>Total Weight</p>
                                <span className='font-bold text-title' style={{fontSize:31}}>{allCounts && allCounts.weight}</span>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    );
}
