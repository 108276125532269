
import React from 'react'
import { url } from '../helpers/helpers'
import { userContext } from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'
import { Link } from 'react-router-dom'

export default function CostingList() {

    const [productsList, setProductsList] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        const formData = new FormData()
        async function fetchData() {
            const response = await fetch(url + 'CostItemList/')

            if (response.ok == true) {
                const data = await response.json()
                setProductsList(data.final_arr)
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    const { user } = React.useContext(userContext)
    if (!user) {
        return window.location = window.location.origin + '/signInPage'
    }

    async function syncCostItem() {
        setLoading(true)
        const response = await fetch(url + 'syncCostItem/')

        if (response.ok == true) {
            alert('done')
            setLoading(false)
        }
    }

    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{ width: '100%', margin: '0 0.5%' }}>
                    <button className='btn btn-primary mt-5 mb-4 float-right' onClick={syncCostItem}>
                        Sync Pricing
                    </button>
                    <a className='btn btn-success mr-4 mt-5 mb-4 float-right' href={`${url}printquotation/`}>
                        Print Quotation
                    </a>
                    {productsList.length > 0 ? <table>
                        <thead style={{ background: 'ghostwhite' }}>
                            <th>Sr. No.</th>
                            <th>Item Name</th>
                            <th>Net Total</th>
                            {/* <th></th> */}
                            <th></th>
                        </thead>

                        {productsList.map((item, index) => {
                            return <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{Math.round(item.nettotal)}</td>
                                <td>
                                    <Link to={`/editCostEntry?id=${item?.id}`}>
                                        Edit
                                    </Link>
                                </td>
                                <td><a target='_blank' href={`${url}admin/erpApp/itemheader/${item.id}/delete/`}>Delete</a></td>
                            </tr>
                        })}

                    </table> : <h1>No Data Found Please Wait</h1>}
                </div>
            </div>
            <Footer />
        </section>
    )
}