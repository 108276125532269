import React from 'react'
import { url } from '../helpers/helpers'
import { userContext } from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'
import {Link} from 'react-router-dom'

export default function EmployeeList() {
    const [productsList, setProductsList] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const response = await fetch(url + 'employeeListAPI/')

            if (response.ok == true) {
                const data = await response.json()
                setProductsList(data.final_arr)
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    const { user } = React.useContext(userContext)
    if (!user) {
        return window.location = window.location.origin + '/signInPage'
    }

    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{ width: '100%', margin: '0 0.5%' }}>
                    {productsList.length > 0 ? <table>
                        <thead style={{ background: 'ghostwhite' }}>
                            <th>Sr. No.</th>
                            <th>Name</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </thead>

                        {productsList.map((item, index) => {
                            return <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <Link to={`/view-employee-details?id=${item?.id}&name=${item?.name}`}>View</Link>
                                </td>
                                <td><a target='_blank' href={`${url}admin/erpApp/employee/${item.id}/change/`}>Edit</a></td>
                                <td><a target='_blank' href={`${url}admin/erpApp/employee/${item.id}/delete/`}>Delete</a></td>
                            </tr>
                        })}

                    </table> : <h1>No Data Found Please Wait</h1>}
                </div>
            </div>
            <Footer />
        </section>
    )
}