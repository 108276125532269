
import './App.css';
import './Panel.css';
import {Switch,Link,Route} from 'react-router-dom'
import BillEntry from './screens/BillEntry';
import Navbar from './components/Navbar';
import SalesReport from './screens/SalesReport';
import UnsavedBillList from './screens/UnsavedBillList';
import UnsavedBill from './screens/UnsavedBill';
import EditBill from './screens/EditBill';
import DayWiseTotalSales from './screens/DayWiseTotalSales';
import ProductWiseSales from './screens/ProductWiseSales';
import Home from './screens/Home';
import ClientsList from './screens/ClientsList';
import TransporterList from './screens/TransporterList';
import ProductList from './screens/ProductList';
import SignIn from './screens/SignIn';
import Footer from './components/Footer';
import RawMaterialList from './screens/RawMaterialList';
import PackingMaterialList from './screens/PackingMaterialList';
import ExtraExpensesList from './screens/ExtraExpensesList';
import CreateCostItem from './screens/CreateCostItem';
import ProductWiseDetailSales from './screens/ProductWiseDetailSales';
import CostingList from './screens/CostingList';
import EmployeeList from './screens/EmployeeList';
import AddWorkToEmployee from './screens/AddWorkToEmployee';
import ViewEmployeeDetails from './screens/ViewEmployeeDetails';
import EditCostItem from './screens/EditCostItem';
import LoaderList from './screens/LoaderList';
import PartyWiseDetailSales from './screens/PartyWiseDetailSales';

function App() {
  return (
    <>
    {/* <Navbar /> */}
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path="/billEntry/">
        <BillEntry />
      </Route>
      <Route exact path="/salesReport/">
        <SalesReport />
      </Route>
      <Route exact path="/unsavedBillList/">
        <UnsavedBillList />
      </Route>
      <Route exact path="/unsavedBill/:id">
        <UnsavedBill />
      </Route>
      <Route exact path="/editBill/:id">
        <EditBill />
      </Route>
      <Route exact path="/dayWiseTotalSales/">
        <DayWiseTotalSales />
      </Route>
      <Route exact path="/productWiseTotalSales/">
        <ProductWiseSales />
      </Route>
      <Route exact path="/productWiseDetailSales/">
        <ProductWiseDetailSales />
      </Route>
      <Route exact path="/partyWiseDetailSales/">
        <PartyWiseDetailSales />
      </Route>
      <Route exact path='/clientList/'>
        <ClientsList />
      </Route>
      <Route exact path='/transporterList/'>
        <TransporterList />
      </Route>
      <Route exact path='/productsList/'>
        <ProductList />
      </Route>
      <Route exact path='/rawMaterialsList/'>
        <RawMaterialList />
      </Route>
      <Route exact path='/packingMaterialsList/'>
        <PackingMaterialList />
      </Route>
      <Route exact path='/extraExpensesList/'>
        <ExtraExpensesList />
      </Route>
      <Route exact path='/createitem/'>
        <CreateCostItem />
      </Route>
      <Route exact path='/CostingList/'>
        <CostingList />
      </Route>
      <Route exact path='/employeeList/'>
        <EmployeeList />
      </Route>
      <Route exact path='/addWorkToEmployee/'>
        <AddWorkToEmployee />
      </Route>
      <Route exact path='/view-employee-details'>
        <ViewEmployeeDetails />
      </Route>
      <Route exact path='/editCostEntry'>
        <EditCostItem />
      </Route>
      <Route exact path='/loaderList'>
        <LoaderList />
      </Route>
      <Route exact path='/signInPage/'>
        <SignIn />
      </Route>
    </Switch>
    </>
  );
}

export default App;
