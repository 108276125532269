import React from 'react'
import { url } from '../helpers/helpers'
import { userContext } from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'

export default function DayWiseTotalSales() {
    const [fromDate, setFromDate] = React.useState('')
    const [toDate, setToDate] = React.useState('')
    const [totalBox, setTotalBox] = React.useState(0)
    const [totalWeight, setTotalWeight] = React.useState(0)
    const [totalAmt, setTotalAmt] = React.useState(0)
    const [loading, setLoading] = React.useState(false)

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');



    const handleFilter = () => {
        if (fromDate && toDate) {
            setLoading(true)
            const formData = new FormData()
            formData.append('from_date', fromDate)
            formData.append('to_date', toDate)
            async function filterData() {
                const response = await fetch(url + 'filterTotalSales/', {
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': csrftoken,
                    },
                    body: formData
                })

                if (response.ok === true) {
                    const data = await response.json()
                    setTotalBox(data.totalBox)
                    setTotalWeight(data.totalWeight)
                    setTotalAmt(data.totalAmt)
                    setLoading(false)

                }
            }
            filterData()
        } else {
            alert('please fill the data')
        }
    }

    const { user } = React.useContext(userContext)
    if (!user) {
        return window.location = window.location.origin + '/signInPage'
    }


    return (
        <>
            {loading && <div id="loader"></div>}
            <PanelNav />

            <div style={{ display: 'flex', flexDirection: 'row', width: '100vw !important' }}>
            <PanelSidebar />
                <div style={{ margin: '0px 0.5%',width:'100%' }}>
                    <div className="display-div border">
                        <div className="form-group">
                            <label htmlFor="">From Date</label>
                            <input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} className='form-control' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">To Date</label>
                            <input type="date" value={toDate} onChange={e => setToDate(e.target.value)} className='form-control' />
                        </div>
                        <div className='btn-div'>
                            <button onClick={() => handleFilter()} className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                    <div>
                    {totalBox ? <div className="container price-container">
                        <div>
                            <p>Total Boxes : <span>{parseFloat(totalBox).toFixed(2)}</span></p>
                            <p>Total Weight : <span>{parseFloat(totalWeight).toFixed(2)} Kg</span></p>
                            <p>Total Amount : <span>{parseFloat(totalAmt).toFixed(2)} Rs</span></p>
                        </div>
                    </div> : <div className='container text-center'>
                            <h1>No data found please wait!</h1>
                        </div>}
                    </div>

                </div>
                
            </div>
            

            <Footer />

        </>
    )
}
