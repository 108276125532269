import React, { useState } from 'react'
import PanelNav from '../components/PanelNav'
import Footer from '../components/Footer'
import PanelSidebar from '../components/PanelSidebar'
import { url } from '../helpers/helpers'

function AddWorkToEmployee() {
    const [loading, setLoading] = React.useState(false)
    const [employees,setEmployees] = useState([])
    const [currentEmployee,setCurrentEmployee] = useState('')
    const [date,setDate] = useState('')
    const [lova,setLova] = useState('')
    const [input,setInput] = useState('')
    const [output,setOutput] = useState('')
    const [rate,setRate] = useState('')

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');

    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault()
        const formData = new FormData()
        formData.append('employee_id',currentEmployee.split('-')[0])
        formData.append('date',date)
        formData.append('lova',lova)
        formData.append('input',input)
        formData.append('output',output)
        formData.append('kum',input - output)
        formData.append('rate',rate)
        formData.append('total',lova * rate)

        const response = await fetch(url + 'addtoworkPOST/',{
            method : 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
            },
            body : formData
        })

        if (response.ok == true){
            setLoading(false)
            window.location.reload()
        }
    }

    React.useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const response = await fetch(url + 'employeeListAPI/')

            if (response.ok == true) {
                const data = await response.json()
                setEmployees(data.final_arr)
                setLoading(false)
            }
        }

        fetchData()
    }, [])


    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{ margin: '0 0.5%' }}>
                    <form onSubmit={e => handleSubmit(e)}>
                        <div className="container-fluid pl-0">
                            <div className="row border-manual py-2 px-2 w-100 align-items-center">
                                <div style={{width : 300}} className='col-md-4 form-group mr-5'>
                                    <label>Employee</label>
                                    <input required list='material-expense-list' autoComplete="off" className='form-control' value={currentEmployee} onChange={e => setCurrentEmployee(e.target.value)} />
                                </div>
                                <datalist id='material-expense-list'>
                                    <option>Select Item</option>
                                    {employees.length > 0 && employees.map(item => <option>
                                        {`${item.id}-${item.name}`}
                                    </option>)}
                                </datalist>

                                <div className='form-group mr-5 col-md-4' >
                                    <label>Date</label>
                                    <input type='date' required autoComplete="off" className='form-control' value={date} onChange={e => setDate(e.target.value)} />
                                </div>


                                <div className='form-group mr-5 col-md-4' >
                                    <label>lova</label>
                                    <input type='text' required autoComplete="off" className='form-control' value={lova} onChange={e => setLova(e.target.value)} />
                                </div>

                                <div className='form-group mr-5 col-md-4' >
                                    <label>input</label>
                                    <input type='text' required autoComplete="off" className='form-control' value={input} onChange={e => setInput(e.target.value)} />
                                </div>

                                <div className='form-group mr-5 col-md-4' >
                                    <label>output</label>
                                    <input type='text' required autoComplete="off" className='form-control' value={output} onChange={e => setOutput(e.target.value)} />
                                </div>


                                <div className='form-group mr-5 col-md-4' >
                                    <label>kum</label>
                                    <p className='form-control'>
                                        {input - output}
                                    </p>
                                </div>

                                <div className='form-group mr-5 col-md-4' >
                                    <label>Rate</label>
                                    <input type='text' required autoComplete="off" className='form-control' value={rate} onChange={e => setRate(e.target.value)} />
                                </div>

                                <div className='form-group mr-5 col-md-4' >
                                    <label>Total</label>
                                    <p className='form-control'>
                                        {lova * rate}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button type='submit' className='btn btn-primary'>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default AddWorkToEmployee