import React from 'react'
import {url} from '../helpers/helpers'
import {userContext} from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'

export default function ClientsList() {

    const [clientList,setClientList] = React.useState([])
    const {user} = React.useContext(userContext)
    const [loading,setLoading] = React.useState(false)

    React.useEffect(()=>{
        setLoading(true)
        async function fetchData(){
            const response = await fetch(url + 'fetchData/')

            if (response.ok == true){
                const data = await response.json()
                setClientList(data.party_name)
                setLoading(false)
            }
        }

        fetchData()
    },[])

    if (!user){
        return window.location = window.location.origin + '/signInPage'
    }


    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
            {clientList.length > 0 ? <table style={{margin:'0 0.5%'}}>
                <thead style={{background:'ghostwhite'}}>
                    <th>Sr. No.</th>
                    <th>Client Name</th>
                    <th>Address</th>
                    <th>Mobile</th>
                    <th></th>
                    <th></th>
                </thead>

                {clientList.map((item,index)=>{
                   return <tr>
                       <td>{index + 1}</td>
                       <td>{item.name}</td>
                       <td>{item.address}</td>
                       <td>{item.mobile_number}</td>
                       <td><a target='_blank' href={`${url}admin/erpApp/party/${item.id}/change/`}>Edit</a></td>
                       <td><a target='_blank' href={`${url}admin/erpApp/party/${item.id}/delete/`}>Delete</a></td>
                   </tr> 
                })}

            </table> : <h1>No Data Found Please Wait</h1>}
            <PanelSidebar />
            </div>
            <Footer />
        </section>
    )
}
