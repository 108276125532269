import React, { useState } from 'react'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'
import { url } from '../helpers/helpers';
import { userContext } from '../context/user';

function PartyWiseDetailSales() {
    const [loading, setLoading] = React.useState(false)
    const [partyNames, setPartyNames] = React.useState([]);
    const [fromDate, setFromDate] = React.useState('')
    const [toDate, setToDate] = React.useState('')
    const [currentParty, setCurrentParty] = React.useState('');
    const [details,setDetails] = useState({})

    React.useEffect(() => {
        setLoading(true)
        async function fetchReports() {
            const response = await fetch(url + 'fetchReports/')
            if (response.ok === true) {
                const data = await response.json()
                setPartyNames(data.party_data);
                setLoading(false);
            }
        }
        fetchReports()
    }, [])

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');


    const { user } = React.useContext(userContext)
    if (!user) {
        return window.location = window.location.origin + '/signInPage'
    }

    async function handleFilter() {
        if (fromDate && toDate && currentParty) {
            setLoading(true)
            const formData = new FormData()
            formData.append('party_id', currentParty.split('-')[0])
            formData.append('from_date', fromDate)
            formData.append('to_date', toDate)

            const response = await fetch(url + 'party-wise-product-details/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
                body: formData
            })

            if (response.ok == true){
                const data = await response.json()
                setLoading(false)
                console.log(data)
                setDetails(data)
            }else{
                setLoading(false)
            }
        }
    }


    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{ width: '100%', margin: '0 0.5%' }}>
                    <div className="display-div border">
                        <div className="form-group">
                            <label htmlFor="">Party</label>
                            <input list='party-list' autoComplete="off" className='form-control' value={currentParty} onChange={e => setCurrentParty(e.target.value)} />
                        </div>

                        <datalist id='party-list'>
                            <option value="">Select Party</option>
                            {partyNames.length > 0 && partyNames.map(item => (
                                <option>
                                    {`${item.id}-${item.name}`}
                                </option>
                            ))}
                        </datalist>

                        <div className="form-group">
                            <label htmlFor="">From Date</label>
                            <input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} className='form-control' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">To Date</label>
                            <input type="date" value={toDate} onChange={e => setToDate(e.target.value)} className='form-control' />
                        </div>
                        <div className='btn-div'>
                            <button onClick={() => handleFilter()} className='btn btn-primary'>Submit</button>
                        </div>
                    </div>

                    {details?.data?.length > 0 ? <section className="table-report">
                            <table style={{ margin: '0px 0.6%' }}>
                                <thead>
                                    <th>Date</th>
                                    <th>name</th>
                                    <th>Total weight</th>
                                    <th>Total amount</th>
                                </thead>

                                <tbody>
                                    {details?.data?.map((item,index) => (
                                        <tr key={index}>
                                            <td>{item?.date}</td>
                                            <td>{item?.name}</td>
                                            <td>{item?.totalWt} Kg</td>
                                            <td>{item?.withoutGst} Rs</td>
                                        </tr>
                                    ))}
                                    
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <b>
                                            {details?.total_weight} Kg
                                            </b>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>

                            </table>
                            </section> : <p>No Record found</p>}
                </div>
            </div>
        </section>
    )
}

export default PartyWiseDetailSales