import React, { useEffect, useState } from 'react'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'
import { url } from '../helpers/helpers'

export default function CreateCostItem() {
    const [loading, setLoading] = React.useState(false)
    const [currentItem, setCurrentItem] = React.useState("")
    const [allItem, setAllItem] = React.useState([])
    const [middle, setMiddle] = React.useState([])
    const [qty, setQty] = useState(0)
    const [price, setPrice] = useState(0)
    const [acw, setAcw] = useState(0)
    const [name,setName] = useState('')

    let subtotal = 0;
    let nettotal = 0;

    React.useEffect(() => {
        setLoading(true)
        async function fetchBasics() {
            const response = await fetch(url + 'fetchData/')

            if (response.ok === true) {
                const data = await response.json()
                // console.log(response)

                setAllItem([...data.raw_materials, ...data.packaging_materials, ...data.extra_expenses])
                setLoading(false)
            }
        }
        fetchBasics()
    }, [])

    useEffect(() => {
        if (currentItem.length > 0) {
            if (currentItem?.split(' ')[(currentItem?.split(' ')?.length - 2)]?.split('₹')[1]){
                setPrice(currentItem.split(' ')[(currentItem.split(' ')?.length - 2)].split('₹')[1])
            }
        }
    }, [currentItem])

    const totalItem = () => {
        if (price && qty) {
            subtotal = parseFloat(parseFloat(price) * parseFloat(qty)).toFixed(2)
            nettotal = parseFloat(parseFloat(price) * parseFloat(qty)).toFixed(2)
            if (acw) {
                nettotal = subtotal / (parseFloat(acw).toFixed(2))
            }
        }
    }

    totalItem()

    function handleSubmitItem(e) {
        e.preventDefault()

        let item = {
            name: currentItem.split('-')[1].split('₹')[0].trim(),
            item_id : currentItem.split('-')[0],
            price,
            type : currentItem.split(' ')[(currentItem.split(' ').length - 1)],
            qty,
            acw,
            subtotal,
            nettotal
        }

        setMiddle([...middle, item])
        subtotal = 0;
        nettotal = 0
        setPrice(0)
        setQty(0)
        setAcw(0)
        setCurrentItem('')
    }

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');

    async function submitFinalItem(){
        if (name.length > 0 && middle.length > 0){
            setLoading(true)
            const formData = new FormData()
            formData.append('name', name)
            formData.append('nettotal', middle.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.nettotal), 0))
            formData.append('middle', JSON.stringify(middle))

            const response = await fetch(url + 'create-item-cost/',{
                method : 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
                body : formData
            });

            if (response.ok === true){
                const data = await response.json()
                setLoading(false)
                window.location.reload()
            }
        }else{
            alert('Please enter name and items')
        }
    }

    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{ margin: '0 0.5%' }}>
                    <div className="container-fluid pl-0">
                        <div className="row border-manual ml-0 py-2 px-2">
                            <div className='row'>
                                <div className='form-group ml-3 w-100'>
                                    <label>Item Name</label>
                                    <input className='form-control' value={name} onChange={e =>setName(e.target.value) }  />
                                </div>
                            </div>
                        </div>

                        <hr />
                    </div>

                    <form onSubmit={e => handleSubmitItem(e)}>
                        <div className="container-fluid pl-0">
                            <div className="row border-manual py-2 px-2 w-100 align-items-center">
                                <div className='form-group w-50 mr-5'>
                                    <label>Material/Expense</label>
                                    <input required list='material-expense-list' autoComplete="off" className='form-control' value={currentItem} onChange={e => setCurrentItem(e.target.value)} />
                                </div>
                                <datalist id='material-expense-list'>
                                    <option>Select Item</option>
                                    {allItem.length > 0 && allItem.map(item => <option>
                                        {`${item.id}-${item.name} ₹${item.price} (${item?.type})`}
                                    </option>)}
                                </datalist>

                                <div className='form-group mr-5'>
                                    <label>Qty</label>
                                    <input required autoComplete="off" className='form-control' value={qty} onChange={e => setQty(e.target.value)} />
                                </div>

                                <div className='form-group'>
                                    <label>Price</label>
                                    <input required autoComplete="off" className='form-control' value={price} onChange={e => setPrice(e.target.value)} />
                                </div>

                                <div className='form-group mr-5'>
                                    <label>ACW</label>
                                    <input required autoComplete="off" className='form-control' value={acw} onChange={e => setAcw(e.target.value)} />
                                </div>
                                <div className='form-group mr-5'>
                                    <label>Sub Total</label>
                                    <p className='form-control'>
                                        {subtotal}
                                    </p>
                                </div>
                                <div className='form-group mr-5'>
                                    <label>Net Total</label>
                                    <p className='form-control'>
                                        {nettotal}
                                    </p>
                                </div>

                                <button type='submit' className='btn btn-primary' style={{ height: 40, width: 100 }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>

                    <table className='table border'>
                        <thead>
                            <th>#</th>
                            <th>name</th>
                            <th>qty</th>
                            <th>price</th>
                            <th>acw</th>
                            <th>sub total</th>
                            <th>net total</th>
                            <th>action</th>
                        </thead>

                        {middle.length > 0 && middle.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.qty}</td>
                                <td>{item?.price}</td>
                                <td>{item?.acw}</td>
                                <td>{parseFloat(item?.subtotal).toFixed(2)}</td>
                                <td>{parseFloat(item?.nettotal).toFixed(2)}</td>
                                <td>
                                    <p onClick={() => {
                                        setMiddle(middle.filter((_, i) => i != index))
                                    }} style={{ cursor: 'pointer', marginBottom: 0 }}>
                                        Delete
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </table>

                    <div className='float-right'>
                        <div className='form-group border mt-5' style={{width : 196}}>
                            <label>Net Total</label>
                            <p>
                                {middle.length > 0 ? middle.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.nettotal), 0).toFixed(2) : 0}
                            </p>
                        </div>

                        <button className='btn btn-success mt-5 w-100' type='button' onClick={submitFinalItem}>
                        Save
                    </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
