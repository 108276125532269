import React from 'react'
import { url } from '../helpers/helpers'

export default function EditTable(props) {
    const { middleEntry, deleteEntry, allGst, index,setMiddleItem,setGrossAmt,setTotalWt,setTotalBox,setAllTaxAmt } = props
    const [boxes, setBoxes] = React.useState(0)
    const [weightPerBox, setWeightPerBox] = React.useState(0)
    const [tax, setTax] = React.useState()
    const [rate,setRate] = React.useState(0)


    React.useEffect(()=>{
        setTax(middleEntry.gst)
        setBoxes(middleEntry.boxes)
        setWeightPerBox(middleEntry.boxWeight)
        setRate(middleEntry.rate)
    },[middleEntry])

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');


    let totalWeight = 0
    let totalAmt = 0
    let taxAmountMiddle = 0

    const calculateAmount = () => {
        if (boxes && weightPerBox) {
            totalWeight = parseFloat(boxes) * parseFloat(weightPerBox)
            totalAmt = parseFloat(totalWeight) * parseFloat(rate)
            if (tax){
                taxAmountMiddle = ((parseFloat(totalAmt)/100.0)*parseFloat(tax))
                totalAmt = parseFloat(totalAmt) + parseFloat(taxAmountMiddle)
            }
        }
    }
    calculateAmount()


    const handleSubmitTwo = (id) => {
        const formData = new FormData()
        formData.append('middle_id',id)
        formData.append('boxes',boxes)
        formData.append('boxWeight',weightPerBox)
        formData.append('totalWt',totalWeight)
        formData.append('tax',tax)
        formData.append('totalAmt',totalAmt)
        formData.append('taxAmt',taxAmountMiddle)
        formData.append('rate',rate)
        
        async function editMiddleData(){
            const response = await fetch(url + 'editMiddleItem/',{
                method : 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
                body : formData
            })

            if (response.ok == true){
                const data = await response.json()
                setMiddleItem(data.final_data)
                setGrossAmt(parseFloat(data.totalAmt))
                setTotalWt(data.totalWt)
                setTotalBox(data.totalbox)
                setAllTaxAmt(data.totalTaxAmt)
                alert('item updated successfully')
            }
        }
        editMiddleData()

    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{middleEntry.name}</td>
            <td>{middleEntry.hsn}</td>
            <td>
                <input required type="text" value={boxes} className='form-control' onChange={e => setBoxes(e.target.value)} />
            </td>
            <td>
                <input required type="text" value={weightPerBox} className='form-control' onChange={e => setWeightPerBox(e.target.value)} />
            </td>
            <td>
                <input required type="text" value={rate} className='form-control' onChange={e => setRate(e.target.value)} />
            </td>
            <td>{parseFloat(totalWeight).toFixed(2)}</td>
            <td>
                <select required className='form-control' value={tax} onChange={e=>setTax(e.target.value)}>
                    {allGst.map(item1 => <option value={item1.name} key={item1.name}>{item1.name}</option>)}
                </select>
            </td>
            <td>
                {parseFloat(totalAmt).toFixed(2)}
        </td>
            <td>
                <button onClick={()=>handleSubmitTwo(middleEntry.id)} className='btn btn-success'> >> </button>
            </td>
            <td onClick={()=>deleteEntry(middleEntry.id)} style={{color:'blue',cursor:'pointer'}}>
                Delete
            </td>
        </tr>
    )
}
