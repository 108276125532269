import React from 'react'
import PanelSidebar from '../components/PanelSidebar'
import PanelNav from '../components/PanelNav'
import { useLocation } from "react-router-dom";
import { url } from '../helpers/helpers';

const ViewEmployeeDetails = () => {
    const [loading, setLoading] = React.useState(false)
    const [list, setList] = React.useState([])
    const [fromDate, setFromDate] = React.useState('')
    const [toDate, setToDate] = React.useState('')

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const id = useQuery().get("id");
    const name = useQuery().get("name");

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');

    React.useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const response = await fetch(url + 'fetchEmployeeWork/' + id + '/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
            })

            if (response.ok == true) {
                const data = await response.json()
                setList(data.list)
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    const handleFilter = async () => {
        if (fromDate && toDate) {
            setLoading(true)
            const formData = new FormData()
            formData.append('from_date', fromDate)
            formData.append('to_date', toDate)

            const response = await fetch(url + 'fetchEmployeeWork/' + id + '/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
                body: formData
            })

            if (response.ok == true) {
                const data = await response.json()
                setList(data.list)
                setLoading(false)
            }

        } else {
            alert('Fill dates')
        }
    }

    function GeneratePDF() {
        let html = `
        <style>
        * {
            margin: 0px;
            padding: 0px;
            box-sizing: border-box;
            overflow-x: hidden;
            font-family: initial;
        }
        .mainPDF {
            width: 100%;
            border: 1px solid black;
            height: fit-content;
            margin: 0 auto;
        }

        .headerRight {
            display: flex;
            place-content: flex-end;
        }

        .headerRight h5 {
            font-size: 15px;
            margin-right: 13px;
            margin-top: 6px;
        }

        .headerMid h1 {
            font-family: initial;
            letter-spacing: 4px;
        }

        .headerMid {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 1px;
        }

        .headerMid h6 {
            letter-spacing: 2px;
            margin-top: -9px;
            font-weight: 400;
            overflow-y: hidden !important;
        }

        .headerMid h6 i {
            font-size: 1.4rem;
            margin-bottom: -4px !important;
        }

        /* table css */
        .table th,
        .table td {
            font-family: initial;
        }

        /* first table */

        .firstcol {
            width: 8%;
        }

        .secondcol {
            width: 58%;
        }

        .thirdcol {
            width: 13%;
        }

        .forthcol {
            width: 15%;
        }


        /* second table */

        .Th1 {
            width: 4.2%;
        }

        .Th2 {
            width: 27%;
        }

        .Th3 {
            width: 9%;
        }

        .Th4 {
            width: 7%;
        }

        .Th5 {
            width: 8%;
        }

        .Th6 {
            width: 10%;
        }

        .Th7 {
            width: 8%;
        }

        .Th8 {
            width: 10%;
        }

        .Th9 {
            width: 28%;
        }
        .secondTable{
            margin-top: -16px;
            border-top: none;
        }
        .secondTable th, .secondTable td{
            text-align: center;
        }
        /* third table */
        .thirdTable {
            margin-top: -16px !important;
        }

        #AMTinWords {
            margin-left: 30px;
            margin-top: 10px;
            text-transform: capitalize;
        }

        .thirdTable h6 {
            overflow-y: hidden !important;
        }

        .thirdTable .col-md-6 {
            border: 1px solid black;
        }

        /* value of supply */
        #VOS {
            font-size: 1rem;
            width: 100%;
            font-weight: 600;
            margin: 0;
            margin-top: 3px;
        }

        .thirdTable h6 {
            font-weight: 600;
        }

        /* our bank detail  */
        #ourBankDetail {
            text-align: center;
            width: 100%;
            letter-spacing: 1px;
        }

        .fourthTable {
            margin-left: -10px;
            margin-right: 4px;
        }

        .fourthTable h5 {
            overflow-y: hidden;
        }

        .fourthTable .fourthTableFirstTH {
            width: 9% !important;
        }

        .fourthTable h5,
        .fourthTable th {
            border: 1px solid black;
        }

        .fourthTable th {
            margin: 1px !important;
        }

        .fourthTableSecondTH {
            width: 15% !important;
        }

        .fourthTableInnerTable {
            margin-top: -7px;
        }

        /* col-md-width */
        .col-md-9 {
            flex: 0 0 73.6% !important;
            max-width: 73.6% !important;
            padding-right: 0px;
        }
        .col-md-3{
            flex: 0 0 26.4% !important;
            max-width: 26.4% !important;
        }

        .stampSection {
            box-sizing: border-box;
            border: 1px solid black;
            height: 9.8rem;
            margin-top: 2px;
            overflow-y: hidden;
        }

        .stampSection p {
            font-size: .8rem;
            text-align: center;
            font-weight: 600;
        }

        .stampSection p:first-child {
            margin-top: .8rem;
        }

        .stampSection p:last-child {
            margin-top: 6rem;
        }

        .notes {
            border: 1px solid black;
            padding-left: 8px;
            overflow-y: hidden;
            margin-top: -12px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .notes p {
            margin: -2px 0;
        }

        .notes h6 {
            overflow-y: hidden;
            font-weight: 600;
        }

        .mailSection {
            border: 1px solid black;
            margin-top: 4px;
            overflow-y: hidden;
            margin-left: 5px;
            margin-right: 5px;
        }

        .mailSection p {
            text-align: center;
            margin: -2px 0;
        }

        .mailSection p a {
            text-decoration: none;
            color: black;
        }

        .mailSection p a:hover {
            color: black;
        }

        .imageSection img {
            max-width: 19rem;
            max-height: 15rem;
            margin-top: 5px;
        }

        .table-bordered,
        .table-bordered td,
        .table-bordered th {
            border-color: black !important;
        }

        .w-75 {
            width: 72.5% !important;
        }

        .w-25 {
            width: 26.5% !important;
            margin: 0 !important;
            padding: 0 !important;
            border-left: 2px solid;
            border-right: 2px solid;
        }
        .w-25 h6{
            margin: 0;
            margin-top: 7px ;
        }
        .w-25 h6, .w-25 p{
            text-align: center;
        }
        #AMTinWords{
            text-align: start;
        }
        @page {
            margin: 30px !important;
        }
        .UPPER {
            text-transform: uppercase;
        }
        .Capitalize{
            text-transform: capitalize;
        }
        </style>
        `

        html = html + document.getElementById("a4-print").innerHTML;

        var myWindow = window.open('', 'Receipt', 'height=400,width=600');
        myWindow.document.write(`<html><head><title>Reciepts</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
                    integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossorigin="anonymous">`);
        myWindow.document.write("</head><body>");
        myWindow.document.write(html);
        myWindow.document.write('</body></html>');
        myWindow.document.close();

        myWindow.onload = function () {
            myWindow.focus();
            myWindow.print();
        }

    }

    return (
        <section>
            {loading && <div id="loader"></div>}
            <PanelNav />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PanelSidebar />
                <div style={{ width: '100%', margin: '0 0.5%' }}>
                    <div className="display-div border">
                        <div className="form-group">
                            <label htmlFor="">From Date</label>
                            <input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} className='form-control' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">To Date</label>
                            <input type="date" value={toDate} onChange={e => setToDate(e.target.value)} className='form-control' />
                        </div>
                        <div className='btn-div'>
                            <button onClick={() => handleFilter()} className='btn btn-primary'>Submit</button>
                        </div>
                        <div className='btn-div'>
                            <button onClick={() => GeneratePDF()} className='btn btn-primary'>Generate PDF</button>
                        </div>
                    </div>
                    {list.length > 0 ? <table>
                        <thead style={{ background: 'ghostwhite' }}>
                            <th>Sr. No.</th>
                            <th>Date</th>
                            <th>lova</th>
                            <th>input</th>
                            <th>output</th>
                            <th>kum</th>
                            <th>rate</th>
                            <th>total</th>
                            {/* <th></th> */}
                            <th></th>
                        </thead>

                        {list.map((item, index) => {
                            return <tr>
                                <td>{index + 1}</td>
                                <td>{item?.date}</td>
                                <td>{item?.lova}</td>
                                <td>{item?.input}</td>
                                <td>{item?.output}</td>
                                <td>{item?.kum}</td>
                                <td>{item?.rate}</td>
                                <td>{item?.total}</td>
                                {/* <td><a target='_blank' href={`${url}admin/erpApp/product/${item.id}/change/`}>Edit</a></td> */}
                                <td><a target='_blank' href={`${url}admin/erpApp/addworktoemployee/${item.id}/delete/`}>Delete</a></td>
                            </tr>
                        })}

                        <tr>
                            <td></td>
                            <td></td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.lova), 0)}</td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.input), 0)}</td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.output), 0)}</td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.kum), 0)}</td>
                            <td></td>
                            <td>
                                <p className='mb-0'>Sub Total : {(list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.total), 0) / 100).toFixed(2)}</p>
                                <p className='mb-0'>KUM : {(list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.kum), 0) / 150).toFixed(2)}</p>
                                <p className='mb-0'>Total : {(list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.total), 0) / 100) - (list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.kum), 0) / 150).toFixed(2)}</p>
                            </td>
                            {/* <td></td> */}
                            <td></td>
                        </tr>

                    </table> : <h1>No Data Found Please Wait</h1>}
                </div>
            </div>

            {/* pdf print */}
            <section class="d-none mainPDF" id='a4-print'>

                <div class="header row" style={{backgroundColor : '#226089',color : 'white'}}>
                    <div class="headerLeft col-xs-2 col-md-2">
                        <img class="img-fluid ml-1 mt-1" src="shri_ganesh.PNG" alt="" />
                    </div>
                    <div class="headerMid col-xs-8 col-md-8">
                        <h1>RAMLAL SOHANLAL</h1>
                        <h6>Near Harsolav talab, Shree Ramsar, Bikaner [ Raj.] - 334001</h6>
                        <h6>Manufacturers of Superior Quality of Papad, Disco & Moongodi</h6>
                        <h6>GSTIN : 08AAYPA6054E1ZL PAN : AAYPA6054E FSSAI : 12219017000187</h6>
                        <h6><i class="fa fa-mobile" aria-hidden="true"></i> 9214824850, 8696718396, 8200552543</h6>
                        {(fromDate && toDate) && <h6> {fromDate} - {toDate} </h6> }
                    </div>
                    <div class="headerRight col-xs-2 col-md-2">
                        <h5>Work Sheet</h5>
                    </div>

                </div>

                <table class="table table-bordered table-responsive table-sm">
                    <tbody>
                        <tr>
                            <th class="firstcol" scope="row">Employee Name</th>
                            <td class="secondcol Capitalize">{name}</td>
                        </tr>
                    </tbody>
                </table>

                <table class="secondTable table table-bordered table-responsive table-sm">
                    <thead>
                        <tr>
                            <th class="Th1">#</th>
                            <th class="Th2">Date</th>
                            <th class="Th3">lova</th>
                            <th class="Th4">input</th>
                            <th class="Th5">output</th>
                            <th class="Th6">kum</th>
                            <th class="Th7">rate</th>
                            <th class="Th8">total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, index) => {
                            return <tr>
                                <td>{index + 1}</td>
                                <td>{item?.date}</td>
                                <td>{item?.lova}</td>
                                <td>{item?.input}</td>
                                <td>{item?.output}</td>
                                <td>{item?.kum}</td>
                                <td>{item?.rate}</td>
                                <td>{item?.total}</td>
                            </tr>
                        })}

                        {list.length > 0 && <tr>
                            <td></td>
                            <td></td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.lova), 0)}</td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.input), 0)}</td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.output), 0)}</td>
                            <td>{list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.kum), 0)}</td>
                            <td></td>
                            <td>
                                <p className='mb-0'>Sub Total : {(list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.total), 0) / 100).toFixed(2)}</p>
                                <p className='mb-0'>KUM : {(list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.kum), 0) / 150).toFixed(2)}</p>
                                <p className='mb-0'>Total : {(list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.total), 0) / 100) - (list.reduce((sum, item) => parseFloat(sum) + parseFloat(item?.kum), 0) / 150).toFixed(2)}</p>
                            </td>
                        </tr>}
                    </tbody>
                </table>

                <div class="row fourthTable">
                    <div class="col-md-9 col-xs-9">
                    </div>
                    <div class="col-md-3 col-xs-3 stampSection">
                        <p>For: Ramlal Sohanlal</p>
                        <p>Authorised Signatory</p>
                    </div>
                </div>


            </section>
            {/* pdf end */}


        </section>
    )
}

export default ViewEmployeeDetails