import React from 'react'

const userContext = React.createContext()

function getUserFromLocalStorage() {
    return localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  }

function User(props) {
    const [user,setUser] = React.useState(getUserFromLocalStorage())

    React.useEffect(()=>{
        localStorage.setItem('user',JSON.stringify(user))
    },[user])

    return (
        <userContext.Provider value={{user,setUser}}>
            {props.children}
        </userContext.Provider>
    )
}

export {userContext,User}