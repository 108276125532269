import React from 'react'

export default function table(props) {
    const {middleEntry,deleteEntry} = props;
    return (
        <div>
            <table>
                <thead>
                    <th>Sr. No</th>
                    <th>Item Name</th>
                    <th>HSN</th>
                    <th>Boxes</th>
                    <th>Weight/Box</th>
                    <th>Rate/kg</th>
                    <th>Total Wt.</th>
                    <th>Total Amt</th>
                </thead>
                {middleEntry.map((item,index)=>{
                    return <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.hsn}</td>
                        <td>{parseFloat(item.boxes).toFixed(2)}</td>
                        <td>{parseFloat(item.boxWeight).toFixed(2)}</td>
                        <td>{parseFloat(item.rate).toFixed(2)}</td>
                        <td>{parseFloat(item.totalWt).toFixed(2)}</td>
                        <td>{parseFloat(item.totalAmt).toFixed(2)}</td>
                        <td onClick={()=>deleteEntry(item.id)} style={{cursor:'pointer',color:'blue'}}>Delete</td>
                    </tr>
                })}
                </table>
        </div>
    )
}
