// const url = 'http://127.0.0.1:8000/'
const media_url = window.location.origin
const url = window.location.origin + '/';
// const url = 'http://rlsl.themad.in' + '/';
// const url = 'https://rlsl.webamplifierapps.com/';

const formatDate = (date) => {
    const date_list = date.split('-');
    return `${date_list[2]}-${date_list[1]}-${date_list[0]}`
}

export {url,formatDate,media_url}