import React from 'react'
import { url } from '../helpers/helpers'
import { userContext } from '../context/user'
import Footer from '../components/Footer'
import PanelNav from '../components/PanelNav'
import PanelSidebar from '../components/PanelSidebar'

export default function Signout() {
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const { user, setUser } = React.useContext(userContext)
    const [loading, setLoading] = React.useState(false)

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');

    const handleSubmit = e => {
        e.preventDefault()

        if (username && password) {
            setLoading(true)
            const formData = new FormData()
            formData.append('username', username);
            formData.append('password', password);

            async function signIn() {
                const response = await fetch(url + 'signIn/', {
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': csrftoken,
                    },
                    body: formData
                })

                if (response.ok == true) {
                    const data = await response.json()
                    setUser(data.data)
                    window.location = window.location.origin
                }
            }

            signIn()
        } else {
            alert('Please Fill The Data!')
        }
    }

    return (
        <section>
            <PanelNav />
            
            
                {loading && <div id="loader"></div>}
                <div className="container">
                    <div style={{ marginTop: 150 }}>
                        <form onSubmit={e => handleSubmit(e)}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Username</label>
                                <input type="text" className="form-control" placeholder="Enter email" value={username} onChange={e => setUsername(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                            </div>
                            <button type="submit" className="btn btn-primary col-md-12">Submit</button>
                        </form>
                    </div>
                </div>
                <Footer />
          
            
        </section>
    )
}
